import $ from 'jquery';
import 'jquery-easy-loading';
import FormValidator from './validator';
import {sendFormData} from './api';
import {toHalfWidthAlphabeNumber} from './utils';

const toggleLoading = ($form:JQuery<HTMLFormElement>, isLoading:boolean) => {
    $form.loading(isLoading ? undefined : 'stop');
}

/**
 * フォームに関する処理を制御するクラス
 */
export default class FormManager {
    /**
     * 対処フォームのjQueryオブジェクト
     */
    private $form: JQuery<HTMLFormElement>;

    /**
     * 入力内容を検証するためのオブジェクト
     */
    private validator: FormValidator;

    /**
     * 最後に記憶した入力情報
     */
    private lastSaveValues: JQuery.NameValuePair[];

    /**
     * 最後に記憶したフォーム内のHTML
     */
    private lastFormInnerHtml: string;

    /**
     * 入力された項目のname属性一覧
     */
    private inputNameSet: Set<string>;

    private get $buttonArea() {
        return this.$form.find('.fm__buttonArea');
    }

    private constructor($form: JQuery<HTMLFormElement>, validator: FormValidator) {
        this.$form = $form;
        this.validator = validator;
        this.lastSaveValues = [];
        this.lastFormInnerHtml = $form.html();
        this.inputNameSet = new Set;
    }

    /**
     * フォームの初期設定
     * @param $form 
     * @returns 
     */
    static async init($form: JQuery<HTMLFormElement>) {
        toggleLoading($form, true);
        const validator = await FormValidator.createInstance($form);
        const formMng = new FormManager($form, validator);
        await formMng.initInstance();
        toggleLoading($form, false);
    }

    /**
     * インスタンスの初期設定
     */
    private async initInstance() {
        this.initEventListeners();
    }

    /**
     * イベント処理を設定する
     */
    private initEventListeners() {
        const $form = this.$form;

        $form
        .on('change', 'input,textarea', (e) => {
            const $item = $(e.target);
            const value = String($item.val());
            const newValue = toHalfWidthAlphabeNumber(value);

            if (value !== newValue) {
                $item.val(newValue).trigger('change');
            }
        })
        .on('keyup change', 'input,textarea', () => {
            this.removeSendResultMessage();
        })
        .on('blur change', 'input,textarea', (e) => {
            // トリガー：要素からフォーカスが外れたとき
            // 動作：一度でもフォーカスがあたった要素→チェック対象
            // 　　　直前にフォーカスがあたった要素→チェック対象
            // 　　　フォーカスがあたったことがない要素→チェック対象外
            const $item = $(e.target);
            const name = $item.prop('name');
            this.saveInputNames(name);

            const isValid = this.validate();
            this.toggleEnableButtons(isValid);
        })
        .on('click', '.fm__confirm', async () => {
            if (this.validate()) {
                this.toConfirmMode();
            }
        })
        .on('click', '.fm__modify', () => {
            this.toModifyMode(true);
        })
        .on('click', '.fm__submit', async () => {
            this.submit();
        })
        // .on('form.send_data', () => {
        //     console.log('送信完了')
        // })
    }


    /**
     * 入力内容を検証する
     * @param name 
     * @returns 
     */
    validate() {
        const $form = this.$form;
        const {result, errors} = this.validator.validate();

        $form.find('.fm__alertWrap').remove();
        $form.find('.fm__invalid').removeClass('fm__invalid');

        Object.entries(errors.all()).forEach(entry => {
            const name = entry[0];

            if ( ! this.inputNameSet.has(name)) return;

            const message = entry[1].join('・');
            const $item = $form.find(`[name="${name}"]`);
            const $wrap = $item.closest('.fm__wrap');
            const $alert = $('<div class="fm__alertWrap">').appendTo($wrap);

            $item.add($wrap).addClass('fm__invalid');
            $alert.text(message);
        });

        return result;
    }

    /**
     * 入力内容をサーバーに送信する
     * @returns 
     */
    async submit() {
        try {
            // 二重submitの防止
            this.toggleEnableButtons(false);
            toggleLoading(this.$form, true);

            // フォームデータ送信
            await sendFormData(this.lastSaveValues);
            this.triggerSendDataEvent();

            // 編集画面に戻る
            this.toModifyMode(false);
            this.setSendResultMessage(true);
            this.purgeInputNames();
            this.toggleEnableButtons(false); // ボタンは手動で無効化（changeを発火すると他の処理が動くので）
        } catch {
            this.setSendResultMessage(false);
            this.toggleEnableButtons(true);
        } finally {
            toggleLoading(this.$form, false);
        }
    }

    /**
     * フォームからデータを送信したことをイベントとして通知する
     */
    triggerSendDataEvent() {
        this.$form.trigger('form.send_data');
    }

    /**
     * フォーム内にあるボタンの有効/無効を切り替える
     * @param enable 
     */
    private toggleEnableButtons(enable:boolean) {
        this.$form.find('button').prop('disabled', ! enable);
    }

    /**
     * 現在の値を記憶する
     */
    private saveValues() {
        this.lastSaveValues = this.$form.serializeArray();
    }

    /**
     * 最後に保存した入力値をフォームに復元する
     */
    private restoreLastSavedValues() {
        const $form = this.$form;

        this.lastSaveValues.forEach(obj => {
            const $items = $form.find(`[name="${obj.name}"]`);
            
            switch ($items.prop('type')) {
                case 'checkbox':
                    $items.filter(`[value="${obj.value}"]`).prop('checked', true);
                    $items.not(`[value="${obj.value}"]`).prop('checked', false);
                    break;
                default:
                    $items.val(obj.value);
                    break;
            }

            $items.trigger('change');
        });
    }

    /**
     * 現状のフォーム内のHTMLを記憶する
     */
    private saveFormHtml() {
        this.lastFormInnerHtml = this.$form.html();
    }

    /**
     * 最後に保存したフォーム内のHTMLを復元する
     */
    private restoreLastSavedFormHtml() {
        this.$form.html(this.lastFormInnerHtml);
    }

    /**
     * 指定された項目を削除し、確認用のラベルに置き換える
     * @param $item 
     * @param value 
     */
    private replaceItemToConfirmLabel($item:JQuery<HTMLElement>, value:string|number){
        const $label = $('<label>').addClass('fm__confirmLabel').text(value);
        
        $item
        .after($label)
        .remove();
    }

    /**
     * 入力項目をラベル化する
     */
    private convertInputItemsToLabel() {
        this.$form.find('.fm__inputWrap').find('input,textarea').each((_, elem) => {
            const $item = $(elem);

            switch ($item.prop('type')) {
                case 'checkbox':
                    if ($item.is(':checked')) {
                        const labelText = $item.closest('label').text();
                        this.replaceItemToConfirmLabel($item.closest('label'), labelText);
                    } else {
                        $item.closest('label').remove();
                    }
                    break;
                default:
                    this.replaceItemToConfirmLabel($item, $item.val()?.toString() || '');
                    break;
            }
        });
    }

    /**
     * ボタン領域内の要素を削除する
     */
    private clearButtonArea() {
        this.$buttonArea.children().remove();
    }

    /**
     * 修正ボタンをボタン領域に追加する
     */
     private appendModifyButtom() {
        this.$buttonArea.append('<button type="button" class="fm__modify">修正</button>')
    }

    /**
     * 送信ボタンをボタン領域に追加する
     */
    private appendSubmitButtom() {
        this.$buttonArea.append('<button type="button" class="fm__submit">送信</button>')
    }

    /**
     * 確認モードであることを示すクラスをフォームに設定する/しないを切り替える
     * @param enable 
     */
    private toggleConfirmingClass(enable:boolean) {
        this.$form.toggleClass('fm__confirming', enable);
    }
    
    /**
     * 確認モードに移行する
     */
    toConfirmMode() {
        this.toggleConfirmingClass(true);
        this.saveValues();
        this.saveFormHtml();
        this.convertInputItemsToLabel();
        this.clearButtonArea();
        this.appendModifyButtom();
        this.appendSubmitButtom();
    }

    /**
     * 編集モードに以降する
     */
    toModifyMode(doRestoreValues:boolean) {
        this.removeSendResultMessage();
        this.toggleConfirmingClass(false);
        this.restoreLastSavedFormHtml();
        doRestoreValues && this.restoreLastSavedValues();
    }

    /**
     * 入力された項目のname属性を記憶する
     * @param name 
     */
    saveInputNames(name:string) {
        this.inputNameSet.add(name);
    }

    /**
     * 入力された項目のname属性リストをクリアする
     */
    purgeInputNames() {
        this.inputNameSet.clear();
    }

    /**
     * 送信結果に関するメッセージを画面上に設定する
     * @param succeed 
     */
    setSendResultMessage(succeed:boolean) {
        const html = succeed
            ? '<div class="fm__result fm__result_success">送信しました</div>'
            : '<div class="fm__result fm__result_failed">送信できませんでした</div>';

        this.removeSendResultMessage();
        this.$buttonArea.after(html);
    }

    /**
     * 送信結果に関するメッセージを画面上から削除する
     */
    removeSendResultMessage() {
        this.$form.find('.fm__result').remove();
    }
}