import { ErrorMessages } from 'validatorjs';

// オリジナルは/validatorjs/src/lang/ja
export default {
    accepted: ':attributeを確認してください',
    after: ':afterより後の日付を入力してください',
    after_or_equal: ':after_or_equal以降の日付を入力してください',
    alpha: '英字のみで入力してください',
    alpha_dash: '英字とダッシュと下線のみで入力してください',
    alpha_num: '英数字のみで入力してください',
    before: ':beforeより前の日付を入力してください',
    before_or_equal: ':before_or_equal以前の日付を入力してください',
    between: {
        numeric: ':min〜:maxの間で指定してください',
        string: ':min〜:max文字を入力してください'
    },
    confirmed: '確認が一致しません',
    email: '正しいメールアドレスを入力してください',
    date: '正しい日付形式を入力してください',
    def: '検証エラーが含まれています',
    digits: ':digits桁以下の数字で入力してください',
    digits_between: ':min桁から:max桁で入力してください',
    different: ':attributeと:differentは同じであってはなりません',
    in: '選択された:attributeは無効です',
    integer: '整数で入力してください',
    hex: '16進数で入力してください',
    min: {
        numeric: ':min以上で入力してください',
        string: ':min文字以上で入力してください'
    },
    max: {
        numeric: ':max以下で入力してください',
        string: ':max文字以下で入力してください'
    },
    not_in: '選択された:attributeは無効です',
    numeric: '数値で入力してください',
    present: ':attributeを入力してください（空欄も可能です）',
    required: '必須項目が入力されていません',
    required_if: ':otherは:valueになったら:attributeは必須です',
    required_unless: ':otherが:valueでなければ:attributeは必須です',
    required_with: ':fieldが空欄でなければ:attributeは必須です',
    required_with_all: ':fieldsが空欄でなければ:attributeは必須です',
    required_without: ':fieldが空欄なら:attributeは必須です',
    required_without_all: ':fieldsが空欄なら:attributeは必須です',
    same: ':attributeと:sameは同じでなければなりません',
    size: {
        numeric: ':sizeを入力してください',
        string: ':size文字で入力してください'
    },
    string: '文字のみで入力してください',
    url: '正しいURIを入力してください',
    regex: ':attributeの値はパターンにマッチする必要があります',
    attributes: {}
} as ErrorMessages;