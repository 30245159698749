import AxiosBase from 'axios';
import {FormSetting, FormSettingResponse, MailSetting} from './types'

/**
 * ベースURLを取得する
 * @returns 
 */
export const getBaseUrl = () => {
    const myUrl = document.querySelector('script[src*="formfw/"]')?.getAttribute('src');
    if ( ! myUrl) {
        throw Error('ソースのURLが不明');
    }

    return myUrl.replace(/formfw\/.*/, 'formfw');
}

const baseUrl = getBaseUrl();
const commonApiAxios = AxiosBase.create({
    baseURL: baseUrl + '/api',
    responseType: 'json',
});
const formGenAxios = AxiosBase.create({
    baseURL: baseUrl + '/form_generator/api',
    responseType: 'json',
});

/**
 * フォーム設定を全て取得する
 * @returns 
 */
export const getAllFormSettings = async () => {
    const response = await commonApiAxios.get<FormSettingResponse[]>('form_settings');
    return response.data;
}

/**
 * フォーム設定を保存する
 * @param saveData 
 * @returns 
 */
export const saveSetting = async (saveData:FormSettingResponse) => {
    const url = `form_settings/${saveData.id}`;
    const response = await formGenAxios.put<FormSettingResponse>(url, saveData);
    return response.data;
}

/**
 * 指定したフォーム設定からHTMLを生成する
 * @param settings 
 * @param withCsrf 
 * @param withFormLib 
 * @returns 
 */
export const createHtml = async (settings:FormSetting[], withCsrf:boolean, withFormLib:boolean) => {
    const url = `html`;
    const response = await formGenAxios.post<{html:string}>(url, {
        settings: settings,
        with_csrf: withCsrf,
        with_form_lib: withFormLib,
    });
    return response.data.html;
}

/**
 * メール設定を全て取得する
 * @returns 
 */
export const getAllMailSettings = async () => {
    const response = await formGenAxios.get<MailSetting[]>('mail_settings');
    return response.data;
}

/**
 * メール設定を保存する
 * @param saveData 
 * @returns 
 */
export const saveMailSetting = async (saveData:MailSetting) => {
    const url = `mail_settings/${saveData.id}`;
    const response = await formGenAxios.put<MailSetting>(url, saveData);
    return response.data;    
}