import axiosBase from 'axios';
import { getBaseUrl } from '../common/api';

const baseUrl = getBaseUrl();
const axios = axiosBase.create({
    baseURL: baseUrl + '/api',
    responseType: 'json',
});

/**
 * 指定されたフォームの入力内容をサーバーに送信する
 * @param formValues 
 * @returns 
 */
export const sendFormData = async (formValues:JQuery.NameValuePair[]) => {
    const formData = new FormData();

    formValues.forEach(obj => {
        formData.append(obj.name, obj.value);
    });

    const response = await axios.post('form_entry', formData);
    return response.data;
}
