/**
 * 指定された文字列に内の全角英数字を半角数字に変換する
 * @param str 
 * @returns 
 */
export const toHalfWidthAlphabeNumber = (str:string) => {
    return str
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        })
        .replace(/＠/g, '@')
        .replace(/[‐―－]/g, '-')
        ;
}