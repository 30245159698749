import $ from 'jquery';
import Validator from 'validatorjs';
import { getAllFormSettings } from '../common/api';
import { FormSetting } from '../common/types';
import validMessage from './validation_message';

Validator.setMessages('ja', validMessage);
Validator.useLang('ja');
Validator.register('numeric_digits_le', function (value, digits) {
    const max = 10 ** Number(digits);
    return Number(value) !== Number.NaN && Math.abs(Number(value)) < max;
}, ':numeric_digits_le桁以下の数値で入力してください');
Validator.register('half_width', function (value) {
    const str = String(value);
    return !!str.match(/^[ -~/]*$/);
}, '半角文字で入力してください');

/**
 * フォームのバリデーション機能を提供するクラス
 */
export default class FormValidator {
    /**
     * 対象フォームのjQueryオブジェクト
     */
    private $form: JQuery<HTMLElement> = $();

    /**
     * バリデーションルール
     */
    private rule: Validator.Rules = {};

    /**
     * フォーム設定リスト
     */
    private settings: FormSetting[] = [];

    private constructor($form: JQuery<HTMLElement>) {
        this.$form = $form;
    }

    /**
     * インスタンスを生成する
     * @returns 
     */
    static async createInstance($form: JQuery<HTMLElement>) {
        const result = new FormValidator($form);
        await result.initRules();
        return result;
    }

    /**
     * 使用するバリデーションルールを初期化する
     */
    private async initRules() {
        const formSetting = (await getAllFormSettings())[0];
        this.settings = formSetting.settings;
        this.rule = {} as Validator.Rules;

        this.settings.forEach(setting => {
            const formType = setting.type;
            const name = setting.name;
            const rules = [];

            if (setting.required) {
                rules.push('required');
            }
            if (formType === 'mail_address') {
                rules.push('email');
            }

            switch (setting.inputType) {
                case 'number':
                    if (0 < setting.maxCharLength) {
                        rules.push({
                            numeric_digits_le: [setting.maxCharLength],
                        });
                    } else {
                        rules.push('numeric');
                    }
                    break;
                default:
                    if (0 < setting.maxCharLength) {
                        rules.push({
                            max: [setting.maxCharLength],
                        });
                    }
                    break;
            }

            switch (setting.enterableChars) {
                case 'half_width':
                    rules.push('half_width');
                    break;
            }

            this.rule[name] = rules;
        });
    }

    /**
     * 指定されたフォーム内の入力項目についてバリデーションチェックを行う
     * @returns 
     */
    validate() {
        const $form = this.$form;
        const data: any = {};
        
        this.settings.forEach(setting => {
            const name = setting.name;
            const $item = $form.find(`[name="${name}"]`);
            const value = $item.val();

            switch ($item.prop('type')) {
                case 'checkbox':
                    data[name] = $item.is(':checked') ? (value || 'on') : null;
                    break;
                default:
                    data[name] = value;
                    break;
            }
        });

        const validation = new Validator(data, this.rule);
        const result = validation.check();

        return {
            result: result,
            errors: validation.errors,
        };
    }
}
